import Link from 'next/link'
import { ResponsiveIcon } from '@/icons/responsive-icon'
import { routeCalculatorRelative } from '@/lib/route-calculator'

export const TrustPilotLink = () => (
  <Link
    href={routeCalculatorRelative.trustpilot}
    className='text-primary-contrast-text'
  >
    <div className='mr-1 flex w-fit grow-0 flex-nowrap items-center justify-start whitespace-nowrap align-middle hover:underline max-md:text-xs md:mr-2'>
      <p className='flex w-fit leading-[18px]'>
        <span className='mr-1'>TrustPilot</span>
        <ResponsiveIcon
          size='s'
          mdSize='s'
          color='light'
          name='rating-trustpilot-star-bold'
          className='mx-1'
        />
        <ResponsiveIcon
          size='s'
          mdSize='s'
          color='light'
          name='rating-trustpilot-star-bold'
          className='mr-1'
        />
        <ResponsiveIcon
          size='s'
          mdSize='s'
          color='light'
          name='rating-trustpilot-star-bold'
          className='mr-1'
        />
        <ResponsiveIcon
          size='s'
          mdSize='s'
          color='light'
          name='rating-trustpilot-star-bold'
          className='mr-1'
        />
        <ResponsiveIcon
          size='s'
          mdSize='s'
          color='light'
          name='rating-trustpilot-half-star-bold'
        />
        <strong className='hidden font-extrabold sm:inline md:mx-1'>
          Excellent
        </strong>
      </p>
    </div>
  </Link>
)
